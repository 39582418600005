<template>
  <div class="hello">

    <div class="F1" v-if="!showheader">
      <img src="../assets/logo2laason2.png" />
      <div class="F1B2">
        <h3>Laboratorio de Alimentos y Aguas de Sonora, S.A. de C.V.</h3>
        <h4>ACCREDITED TESTING LABORATORY ISO/IEC 17025:2017 BY PERRY JOHNSON LABORATORY ACREDITATION INC.</h4>
        <p>Garmendia 80 San Benito, 83190, Hermosillo, Sonora</p>
        <p>CERTIFICATE N° L-20 143 /ACREDITATION NUMBER 89525</p>
        
      </div>
    </div>

    <div id="pdfdoc" ref='content'>
      <div>
        <div class="F1" v-if="showheader">
          <img src="../assets/logo2laason2.png" />
          <div class="F1B2">
            <h3>Laboratorio de Alimentos y Aguas de Sonora, S.A. de C.V.</h3>
            <h4>LABORATORIO DE ENSAYOS ACREDITADO ISO/IEC 17025:2017 POR PERRY JOHNSON LABORATORY ACREDITATION INC.</h4>
            <p>Garmendia N°80, Col.San Benito C.P. 83190 Hermosillo, Sonora.</p>
            <p>CERTIFICATE N° L-20 143 /ACREDITATION NUMBER 89525</p>
            <!--<p>REGISTRO COFEPRIS S.S.P. 1315/15</p>-->
          </div>
        </div>

        <div class="F2">

          <div class="F2F1">
            <div class="F2BlueLine"></div>
            <p>INFORME DE PRUEBA</p>
            <div class="F2BlueLine"></div>
          </div>

          
          <div class="F2F2">
            <h3>Número de Informe</h3>
            <p>{{PDFinfo.servicio.clave_LAASON + '-' + PDFinfo.num}}</p>
            <h4>Fecha de Emisión</h4>
            <p>{{PDFinfo.fecha_f.slice(0, 10)}}</p>
          </div>

        </div>

        <div class="F3">

          <div class="F3F1">
            <div class="F3BlueLine"></div>
            <p>DATOS DEL CLIENTE</p>
            <div class="F3BlueLine"></div>
          </div>

          <div class="F3F2">
            <div class="F3F2_F1">
              <h3>Cliente/Empresa</h3>
            
                <p v-if="PDFinfo.servicio.id_sucursal !=null && PDFinfo.servicio.sucursal.name">{{PDFinfo.servicio.sucursal.name}}</p>
                <p v-else> {{PDFinfo.servicio.cliente.name}} </p>
            </div>
            <div class="F3F2_F2">
              <h3>Dirección</h3>
              
                 <p v-if="PDFinfo.servicio.id_sucursal ==null">{{PDFinfo.servicio.cliente.address}}</p>
                <p v-else>{{PDFinfo.servicio.sucursal.address}}</p>
            </div>
            <div class="F3F2_F3">
              <h3>RFC</h3>
              <p>{{PDFinfo.servicio.cliente.td_rfc}}</p>
            </div>
          </div>

        </div>

        <div class="F4">

          <div class="F4F1">
            <div class="F4BlueLine"></div>
            <p>IDENTIFICACIÓN DE LA MUESTRA</p>
            <div class="F4BlueLine"></div>
          </div>

          <div class="F4F2">
            <div class="F4F2_F1">
              <h3>Lugar de toma de muestra</h3>
              <p>{{PDFinfo.extra.direc_toma}}</p>
              <h4>Tipo de muestra</h4>
              <p class="F4F2_F1F4">{{PDFinfo.tipo}}</p>
            </div>
            <div class="F4F2_F2">
              <h3>Observaciones</h3>
              <p>{{PDFinfo.extra.obv}}</p>
            </div>
            <div class="F4F2_F3">
              <h3>Fecha de análisis</h3>
              <p>{{PDFinfo.fecha_i.slice(0, 10) + "   -   " + PDFinfo.fecha_f.slice(0, 10)}}</p>
            </div>
          </div>

        </div>

        <div class="F5">

          <div class="F5F1">
            <div class="F5BlueLine"></div>
            <p>DATOS DE RECOLECCIÓN O TOMA DE MUESTRA</p>
            <div class="F5BlueLine"></div>
          </div>

              <div class="F5B1_F0">
                <h3>Dirección de toma de muestra</h3>
                <p v-if="!PDFinfo.extra.direc_toma">----</p>
                <p v-else>{{PDFinfo.extra.direc_toma}}</p>
              </div>
          <div class="contenidoF5">
            <div class="F5B1">


              <div class="F5B1_F1">
                <h3>Apariencia de la muestra</h3>
                <p>{{PDFinfo.extra.apar}}</p>
              </div>
              <div class="F5B1_F2">
                <h3>Fecha de toma de muestra</h3>
                <p>{{PDFinfo.extra.fecha_toma.slice(0, 10)}}</p>
              </div>
              <div class="F5B1_F3">
                <h3>Fecha de recepción</h3>
                <p>{{PDFinfo.servicio.fecha}}</p>
              </div>
              <div class="F5B1_F4">
                <h3>Toma de muestra por</h3>
                <p>{{PDFinfo.toma}}</p>
              </div>
            </div>

            <div class="F5B2">
              <div class="F5B2_F1">
                <h3>Hora de toma de muestra</h3>
                <p>{{PDFinfo.extra.hr}}</p>
              </div>
              <div class="F5B2_F2">
                <h3>Hora de recepción</h3>
                <p>{{PDFinfo.servicio.hr}}</p>
              </div>
            </div>
          </div>

        </div>

        <div class="F6">
          <div class="F6F1">
            <div class="F6BlueLine"></div>
            <p>ENVASE, VOLUMEN Y PRESERVACIÓN DE LA MUESTRA</p>
            <div class="F6BlueLine"></div>
          </div>

          <div class="F6Table">

            <div class="F6Title">
              <p class="F6Title_C1">IDENTIFICACION DEL ENVASE</p>
              <p class="F6Title_C2">CANTIDAD</p>
              <p class="F6Title_C3">PRESERVACIÓN</p>
              <p class="F6Title_C4">TRANSPORTE</p>
            </div>

            <div>

              <div class="F6Content">
                <p class="F6Content_C1">{{PDFinfo.extra.ident_env.slice(0, 10)}}</p>
                <p class="F6Content_C2">{{PDFinfo.extra.cant.slice(0, 10)}}</p>
                <p class="F6Content_C3">{{PDFinfo.extra.pres.slice(0, 10)}}</p>
                <p class="F6Content_C4">{{PDFinfo.extra.transp.slice(0, 10)}}</p>
              </div>
              
            </div>

          </div>
        </div>

        <div class="F7">
          <div class="F7F1">
            <div class="F7BlueLine"></div>
            <p>RESULTADOS DE LA MUESTRA</p>
            <div class="F7BlueLine"></div>
          </div>

          <div class="F7Table">

            <div class="F7Title">
              <p class="F7Title_C1">PRUEBA</p>
              <p class="F7Title_C2">RESULTADO</p>
              <p class="F7Title_C3">UNIDADES</p>
              <p class="F7Title_C4">LÍMITE DE CUANTIFICACIÓN</p>
              <p class="F7Title_C5">REFERENCIA</p>
            </div>
            
            <div>

              <div v-for="(item, key) in PDFinfo.resultados" :key="key">
                <div class="F7Content">
                  <p class="F7Content_C1">{{item.pname}}</p>
                  <p class="F7Content_C2">{{item.resultado}}</p>
                  <p v-if="item.uni == 'null' || item.uni == undefined" class="F7Content_C3"></p>
                  <p v-else class="F7Content_C3">{{ item.uni}}</p>
                  <p v-if="item.lc == 'null' || item.lc == undefined" class="F7Content_C4"></p>
                  <p v-else class="F7Content_C4">{{item.lc}}</p>
                  <p class="F7Content_C5">{{item.ref}}</p>
                </div>
              </div>
              

            </div>

          </div>

          <div v-if="this.conteo >= 20">
            <div class="WhiteSpace"></div>
          </div>

          <div class="F8Table">
            
            <div class="F8F1">
              
              <div class="F8Content">
                <p class="F8Content_C3">ANALISTA</p>
                <p class="F8Content_C2">{{PDFinfo.f_analista}}</p>
              </div>
              <div class="F8Content">
                <p class="F8Content_C3">LIBERACIÓN</p>
                <p class="F8Content_C2">{{PDFinfo.f_liberacion}}</p>
              </div>
              <div class="F8Content">
                <p class="F8Content_C3">SUPERVISÓ</p>
                <p class="F8Content_C2">{{PDFinfo.superviso}}</p>
              </div>
            </div>

          </div>

        </div>
        
        

        <div v-if="this.extradata == 'No se encontraron coincidencias.'" class="F9">
          <p>{{this.extradataFirst.texto}}</p>
        </div>
        <div v-else class="F9">
          <p>{{this.extradata.texto}}</p>
        </div>

        <div v-if="this.extradata == 'No se encontraron coincidencias.'" class="F9_2">
          <p>{{this.extradataFirst.texdos}}</p>
        </div>
        <div v-else class="F9">
          <p>{{this.extradata.texdos}}</p>
        </div>
        
      </div>
    </div>
    <div class="F10" ref='footer'>
      <img class="QR" src="../assets/QR.png">
          
          <div>
            <img src="../assets/Firma_Maritza2.png"/>
            <h3>Q.B. MARITZA MARIA MORENO VAZQUEZ M.C.</h3>
            <p>DIRECTOR GENERAL RESPONSABLE</p>
          </div>
          
                    <img class="ESR" src="../assets/ESR.png">
                    <img class="ILAC" src="../assets/ILAC.png">
                    <img class="PJLA" src="../assets/PJLA2.png">
        </div>

        <div class="F11">
          <h3>REV-01</h3>
          <p>FA-18</p>
        </div>
    <div class="F12">
        <p @click="wait()" class="btnRegresar5">Regresar</p>  <button class="DButton" @click="downloadWithCSS(true), showheader=true">Descargar PDF</button> 
        <button class="DButton" @click="downloadWithCSS(false)">Descargar PDF Sin Logos</button> 
      <form
        @submit.prevent="
          submit(
            file1,
            muestra
          )
        "
      >
        <div v-if="identity.role !='cliente'" class="enviar">
          <label>Archivo
            <input type="file" id="file1" ref="file1" v-on:change="handleFileUpload()"/>
          </label>
          <button class="DButton" @click="submit">Enviar PDF</button>
        </div>

      </form>
      
    </div>
        
        <div v-if="status !=''" class="status_messages">
            <div v-if="status =='success'" class="msg msg_success">
                <p>{{message}}</p>
            </div>
            <div  v-if="status =='error'" class="msg msg_error">
                <p>{{message}}</p>
            </div>
        </div>

  <img id="ipr" src="../assets/Group4.jpg">

  </div>
</template>

<script>

import html2PDF from "html2pdf.js";
import jspdf  from "jspdf";
import html2canvas from "html2canvas";
import canvas from 'canvas';
import {mapActions} from 'vuex';
import axios from "axios";
import userService from '../services/user.service'


export default {
  
  name: 'PDFcreate',
  data (){
    return {
      status:'',
      message:'',
      file1: null,
      msg:"",
      inicialesFN: '',
      inicialesSN: '',
      inicialesTN: '',
      altura: 0,
      conteo: 0,
      extradata:'',
      extradataFirst:'',
      showheader: true,
      totalPages: 1,
    }
  },
  async created(){
  this.getIdentity()
    let arreglo = await this.getAllInfoTxt('texto')
    this.extradataFirst = arreglo.result[0]
    
    let payload ={
      option:'texto_muestra',
      id:this.PDFinfo.id
    }
    this.extradata = await this.getInfoByIdTxtMuestra(payload)
    
    var informacion = this.$store.getters["PDF/getAdded"];
    var FN = informacion.analistas.analista.nombre;
    var SN = informacion.analistas.liberacion.nombre;
    var TN = informacion.analistas.superviso.nombre;

    var separador = " ";
    var arregloDeSubCadenas = FN.split(separador);
    var arregloDeSubCadenasSN = SN.split(separador);
    var arregloDeSubCadenasTN = TN.split(separador);

    for (var i=0; i<arregloDeSubCadenas.length; i++){
        this.inicialesFN = this.inicialesFN + arregloDeSubCadenas[i].substring(0, 1);
    }
    for (var i=0; i<arregloDeSubCadenasSN.length; i++){
        this.inicialesSN = this.inicialesSN + arregloDeSubCadenasSN[i].substring(0, 1);
    }
    for (var i=0; i<arregloDeSubCadenasTN.length; i++){
        this.inicialesTN = this.inicialesTN + arregloDeSubCadenasTN[i].substring(0, 1);
    }

/* INICIA PROCESO DE EXCESO DE RESULTADOS */
    var solicitud = this.$store.getters["PDF/getAdded"];
      let muestras = solicitud.resultados;
      var resultadoS = 0;

      for(var i=0; i< muestras.length; i++){
              resultadoS = resultadoS + 1          
      }
      
      this.conteo = resultadoS
/* TERMINA PROCESO DE EXCESO DE RESULTADOS */

  },
  computed:{    
    
        muestra(){
            return this.$store.getters["muestras/getAdded"]
        },
        PDFinfo(){
          let array = this.$store.getters["PDF/getAdded"]
          
            return this.$store.getters["PDF/getAdded"]
        },
         identity() {
      return this.$store.getters['admin/getIdentity'];
    },
  },
  methods:{
    
      ...mapActions('main', ['addItem']),
      ...mapActions("texto", ["getAllInfoTxt"]),
      ...mapActions('texto', ['getInfoByIdTxtMuestra']),
      
        wait: function(){
          setTimeout(() => this.$router.go(), 200);
        },

        getIdentity: async function (){
        let response =  await this.$store.dispatch("admin/getData");
        return response
      },
      handleFileUpload(){
        this.file1 = this.$refs.file1.files[0];
      },
      

      TomaMuestra(fulldate) {
        let date = fulldate.slice(0, 10)
        let hour = fulldate.slice(11, 16)

        date = date.split("-").join('/')

        let fechahora = date +" "+ hour

        return fechahora;
      },
      
      async downloadWithCSS(HoldHeader) {

        this.showheader = HoldHeader
        const pages = document.getElementById('pdfdoc');
        
        let clalason = this.PDFinfo.servicio.clave_LAASON+'-'+this.TomaMuestra(this.PDFinfo.servicio.fecha)
        
        let sheight=screen.height * 1.4,swidth=screen.width * 1.3;
        
        var opt
        let margintop = 5
        if(!HoldHeader) margintop=26
        opt = {
          margin:       [(sheight * margintop) / 100,30,(sheight * 15) / 100,30],
          filename:     `Informe de prueba ${clalason}.pdf`,
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { scale: 2 },
          jsPDF:        { unit: 'px', format: [pages.getBoundingClientRect().width, pages.getBoundingClientRect().width * 1.29], orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        if(this.PDFinfo.extra.pdf_saved != 'si') {
                
          let data = {
            id: this.PDFinfo.id
          }
          await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras/pdfsave', item: data});
        }

        html2PDF().set(opt).from(pages).toPdf().get('pdf').then(async function (pdf) {
          
          var totalPages = pdf.internal.getNumberOfPages();
          
          function getBase64Image(img) {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL();
            return dataURL;
          }

          var base64 = getBase64Image(document.getElementById("ipr"));
          
          for (let i = 1; i <= totalPages; i++) {
              
            pdf.setPage(i);
            if(HoldHeader){
              pdf.addImage(base64, 'PNG', 32, (pages.getBoundingClientRect().width * 1.29) * .90, pages.getBoundingClientRect().width * .94, (pages.getBoundingClientRect().width * 1.29) * .08, { baseline: 'bottom' }, 'SLOW');
            }
            
            pdf.setFontSize((swidth * 0.9) / 100)
            pdf.setTextColor(86,86,86);
          
          }
        }).save();


          
      },

        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
    submit: async function ( file1, muestra ) {
      this.status='';
      this.message='';
        
         /*var pdfson = doc.output();
        var data = new FormData();
        data.append("data", pdfson);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/kiC_UUf/ja0-dDu/send/create', true);
        xhr.send(data);*/
        

        //document.body.appendChild(img);
        
        try {
          var extencion = this.$refs.file1.files[0].name;
          var extencionF = extencion.substring(extencion.length - 3, extencion.length);
          var correo = this.PDFinfo.servicio.cliente.email;
          var name = this.PDFinfo.servicio.cliente.name
          var clave_LAASON = this.PDFinfo.servicio.clave_LAASON
          var idmuestra = this.PDFinfo.id
        }catch(e) {
          this.message='Correo no enviado, ingresar archivo';
          this.status='error';
        }
        
        
        if(file1 == null || file1 == undefined || file1 == ''){
          this.message='Correo no enviado, ingresar archivo';
          this.status='error';
        }else if(extencionF != 'pdf'){
          this.message='Correo no enviado, extension no valida';
          this.status='error';
          this.delStatus()
        }else{

          let token = userService.getToken() 
          let  headers = {
            Authorization: token
          }


                var data = new  FormData();
              
                  data.append('image', file1);
                  data.append('_method', 'POST');
                  data.append('info', muestra);
                  data.append('mail', correo);
                  data.append('name', name)
                  data.append('clave',clave_LAASON)
                  data.append('idmuestra', idmuestra)
                  
                  //let result = this.$store.dispatch("main/addItem",  {option:'send', item: data});
                  let result = axios.post(`/kiC_UUf/ja0-dDu/send/create`, data, {headers});
                  this.message='Correo enviado exitosamente';
                  this.status='success';
                  this.delStatus()

        }



    },

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.WhiteSpace {
    width: auto;
    height: 15vw;
}
    

  .F1 {
    display: flex;
    place-content: center;
    align-items: end;
  }
    .F1 img {
      width: auto;
      height: 10vw;
      margin-right: 1vw;
    }
  .F1B2 {
    text-align: left;
    line-height: 0.3vw;
  }
    .F1B2 h3{
      letter-spacing: 0.001vw;
      color: rgb(41, 38, 38);
      font-weight: 600;
      font-size: 1.6vw;
    }
    .F1B2 h4{
      width: 59vw;
      height: auto;
      color: rgb(41, 38, 38);
      font-weight: 500;
      font-size: 1vw;
    }
    .F1B2 p{
      color: rgb(41, 38, 38);
      font-weight: 600;
      font-size: 1vw;
      margin-bottom: 0.9vw;
    }

.F2F1 {
  display: flex;
    height: 2vw;
    justify-content: center;
    align-items: center;
}
  .F2BlueLine {
    background-color: rgb(7, 130, 151);
    width: 24vw;
    height: .2vw;
  }
    .F2F1 p{
      width: 18vw;
      color: rgb(7, 130, 151);
      font-weight: 600;
      font-size: 1.6vw;
      margin: 0vw 1vw;
    text-align: center;
    }
  
  .F2F2{
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F2F2 h3 {
      width: 10vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: 0vw;
    }
    .F2F2 h4 {
      width: 9vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin-left: 19vw;
    }
    .F2F2 p {
      width: 6vw;   
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 3vw;
      white-space: nowrap;
    }


.F3F1 {
  display: flex;
    height: 2vw;
    justify-content: center;
    align-items: center;
}
  .F3BlueLine {
    background-color: rgb(7, 130, 151);
    width: 26vw;
    height: .23vw;
  }
    .F3F1 p{
      width: 14vw;
      height: auto;
      color: rgb(7, 130, 151);
      font-weight: 600;
      font-size: 1.3vw;
      margin: 0vw 1vw;
      text-align: center;
    }

.F3F2 {
    line-height: 0vw;
}
  .F3F2_F1 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F3F2_F1 h3 {      
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F3F2_F1 p {      
      width: 50vw;
      height: auto;
      text-align: left;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 5vw;
    }
  .F3F2_F2 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F3F2_F2 h3 {      
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F3F2_F2 p {      
      width: 50vw;
      height: auto;
      text-align: left;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 8vw;
    }
  .F3F2_F3 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F3F2_F3 h3 {      
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F3F2_F3 p {      
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 10.3vw;
    }


.F4F1 {
  display: flex;
    height: 2vw;
    justify-content: center;
    align-items: center;
}
  .F4BlueLine {
    background-color: rgb(7, 130, 151);
    width: 19.5vw;
    height: .23vw;
  }
    .F4F1 p{
      width: 27vw;
      height: auto;
      color: rgb(7, 130, 151);
      font-weight: 600;
      font-size: 1.6vw;
      margin: 0vw 1vw;
      text-align: center;
    }

.F4F2 {
    line-height: 0vw;
}
.F4F2_F1 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F4F2_F1 h3 {    
      width: 14vw;  
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F4F2_F1 h4 {      
      width: 12vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: 0vw;
      margin-left: 2vw;
    }
    .F4F2_F1 p {      
      width: 24vw;
      line-height: 1.2vw;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw;
      margin-left: 0.1vw;
      overflow-wrap: break-word;
    }
    p.F4F2_F1F4 {
      width: 12vw !important;
    }
  .F4F2_F2 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F4F2_F2 h3 {      
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F4F2_F2 p {
      width: 52vw;
      height: fit-content;
      line-height: 1vw;
      text-align: left;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 5vw;
    }
  .F4F2_F3 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F4F2_F3 h3 {      
      width: 8vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F4F2_F3 p {      
      width: 12vw;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 3.1vw;
    }


.F5F1 {
  display: flex;
    height: 2vw;
    justify-content: center;
    align-items: center;
}
  .F5BlueLine {
    background-color: rgb(7, 130, 151);
    width: 13.5vw;
    height: .23vw;
  }
    .F5F1 p{
      width: 39vw;
      height: auto;
      color: rgb(7, 130, 151);
      font-weight: 600;
      font-size: 1.6vw;
      margin: 0vw 1vw;
      text-align: center;
    }


.contenidoF5 {
  display: flex;
}
.F5B1 {
    line-height: 0vw;
}
.F5B1_F0 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F5B1_F0 h3 {      
      width: 11.3vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F5B1_F0 p {      
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 4vw;
      width: 49vw;
      overflow-wrap: break-word;
    }
.F5B1_F1 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F5B1_F1 h3 {      
      width: 11.3vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F5B1_F1 p {      
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 4vw;
    }
  .F5B1_F2 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F5B1_F2 h3 {      
      width: 12.3vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F5B1_F2 p {
      width: 6vw;
      text-align: left;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 3vw;
    }
  .F5B1_F3 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F5B1_F3 h3 {
      width: 9.25vw; 
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F5B1_F3 p {
      width: 6vw;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 6vw;
    }
  .F5B1_F4 {
    display: flex;
    align-items: center;
    margin-left: 8vw;
  }
    .F5B1_F4 h3 {
      width: 10.2vw;
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: .2vw 0vw;
    }
    .F5B1_F4 p {      
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 5vw;
    }

.F5B2 {
    padding: 1.5vw 0vw;
}
.F5B2_F1 {
    display: flex;
    align-items: center;
    margin-left: 10vw;
  }
    .F5B2_F1 h3 {   
      width: 12vw;   
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: 0vw;
    }
    .F5B2_F1 p {   
      width: 6vw;   
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 4vw;
    }
  .F5B2_F2 {
    display: flex;
    align-items: center;
    margin-left: 10vw;
  }
    .F5B2_F2 h3 {   
      width: 9vw;      
      color: rgb(153, 147, 147);
      font-size: 1vw;
      font-weight: 500;
      margin: 0vw;
    }
    .F5B2_F2 p {
      width: 6vw;
      text-align: left;
      color: rgb(121, 121, 121);
      font-size: 1vw;
      font-weight: 600;
      margin: 0vw 7vw;
    }


.F6F1 {
  display: flex;
  height: 2vw;
  justify-content: center;
  align-items: center;
}
  .F6BlueLine {
    background-color: rgb(7, 130, 151);
    width: 10.5vw;
    height: .23vw;
  }
    .F6F1 p{
      width: 44vw;
      height: auto;
      color: rgb(7, 130, 151);
      font-weight: 600;
      font-size: 1.6vw;
      margin: 0vw 1vw;
      text-align: center;
    }

.F6Table {
    margin: 1vw 0vw;
}
.F6Title {
  display: flex;
  justify-content: center;
  align-items: center;
}
  .F6Title p{
    text-align: center;
    color: white;
    background-color: rgb(7, 130, 151);
    font-weight: 600;
    font-size: 0.7vw;
    margin: 0vw 0.03vw;
  }
  .F6Title_C1 {
    width: 18vw;
    height: 1.6vw;
    padding-top: 0.45vw;
  }
  .F6Title_C2 {
    width: 15vw;
    height: 1.6vw;
    padding-top: 0.45vw;
  }
  .F6Title_C3 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.45vw;
  }
  .F6Title_C4 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.45vw;
  }

.F6Content {
  display: flex;
  justify-content: center;
  align-items: center;
}
  .F6Content p{
    text-align: center;
    color: rgb(121, 121, 121);
    background-color: rgb(245, 245, 245);
    font-weight: 700;
    font-size: 0.8vw;
    margin: 0vw 0.03vw;
    margin-bottom: 0.05vw;
  }
  .F6Content_C1 {
    width: 18vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F6Content_C2 {
    width: 15vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F6Content_C3 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F6Content_C4 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }


.F7F1 {
  display: flex;
  height: 2vw;
  justify-content: center;
  align-items: center;
}
  .F7BlueLine {
    background-color: rgb(7, 130, 151);
    width: 19.5vw;
    height: .23vw;
  }
    .F7F1 p{
      width: 26vw;
      height: auto;
      color: rgb(7, 130, 151);
      font-weight: 600;
      font-size: 1.6vw;
      margin: 0vw 1vw;
      text-align: center;
    }

.F7Table {
    margin: 1vw 0vw;
}
.F7Title {
  display: flex;
  justify-content: center;
  align-items: center;
}
  .F7Title p{
    text-align: center;
    color: white;
    background-color: rgb(7, 130, 151);
    font-weight: 600;
    font-size: 0.7vw;
    margin: 0vw 0.05vw;
  }
  .F7Title_C1 {
    width: 17vw;
    height: 1.6vw;
    padding-top: 0.45vw;
    border-color: white;
  }
  .F7Title_C2 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.45vw;
    border-color: white;
  }
  .F7Title_C3 {
    width: 5vw;
    height: 1.6vw;
    padding-top: 0.45vw;
    border-color: white;
  }
  .F7Title_C4 {
    width: 11.2vw;
    height: 1.6vw;
    padding-top: 0.45vw;
    border-color: white;
  }
  .F7Title_C5 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.45vw;
    border-color: white;
  }
.F7Content {
  display: flex;
  justify-content: center;
  align-items: center;
}
  .F7Content p{
    text-align: center;
    color: rgb(121, 121, 121);
    background-color: rgb(245, 245, 245);
    font-weight: 700;
    font-size: 0.8vw;
    margin: 0vw 0.03vw;
    margin-bottom: 0.09vw;
  }
  .F7Content_C1 {
    width: 17vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F7Content_C2 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F7Content_C3 {
    width: 5vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F7Content_C4 {
    width: 11.2vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F7Content_C5 {
    width: 16vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }

.F8Table {
    width: 65.5vw;
    margin: 1vw 0vw;
    margin-left: 6.3vw;
    border-top: 0.2vw solid rgb(7, 130, 151);
    border-bottom: 0.2vw solid rgb(7, 130, 151);
}
.F8F1 {
  display: flex;
  padding-left: 0.3vw;
}
.F8Content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw 0vw;
}
  .F8Content p{
    text-align: center;
    color: rgb(121, 121, 121);
    background-color: #FFFFFF;
    font-size: 0.8vw;
    margin: 0vw 0.03vw;
    margin-bottom: 0.05vw;
  }
  .F8Content_C1 {
    width: 17vw;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  .F8Content_C2 {
    width: fit-content;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  p.F8Content_C2 {
    margin-right: 9.874999999999996VW;
    font-weight: 700;
  }
  .F8Content_C3 {
    width: fit-content;
    height: 1.6vw;
    padding-top: 0.3vw;
  }
  p.F8Content_C3 {
    margin-right: 1.5729166666666666VW;
    font-weight: 600;
  }



.F9 {
  width: 65.4vw;
  height: auto;
  margin: auto;
  margin-top: 1.5vw;
  text-align: justify;
  color: rgb(121, 121, 121);
  margin-left: 6.3vw;
  letter-spacing: 0.001vw;
}
  .F9 p {
    font-size: 1.02vw;
    line-height: 1vw;
  }


.F9_2 {
  width: 65.4vw;
  height: auto;
  margin: auto;
  margin-top: 5vw;
  text-align: justify;
  color: rgb(121, 121, 121);
  margin-left: 6.3vw;
}
  .F9_2 p {
    font-size: 1.02vw;
    line-height: 1vw;
    margin: 0.1vw 0vw;
  }


.F10 {
    width: 76.3vw;
    display: flex;
    text-align: center;
    justify-content: center;
}
  .F10 img {
    width: 26vw;
    height: auto;
    margin-bottom: -5vw;
    position: relative;
  }
  .F10 h3 {
    text-align: center;
    font-size: 1.1vw;
    color: rgb(7, 130, 151);
    margin-top: 1vw;
    margin-bottom: 0.3vw;
  }
  .F10 p {
    width: 11vw;
    font-size: 1vw;
    margin: auto;
    text-align: center;
    color: rgb(121, 121, 121);
  }
    .QR  {
      width: 7.640522875816995VW !important;
      height: 7.640522875816995VW !important;
      display: block;
      margin-right: 2.5vw !important;
      margin-top: 1.5vw !important;
    }
    .ESR  {
      width: 12.03268VW !important;
      height: 4.718954VW !important;
      margin-top: 2.5vw !important;
      margin-right: 2vw;
      margin-left: 3vw;
    }
    .ILAC  {
      width: 5.189542VW !important;
      height: 4.862745VW !important;
      margin-top: 2.5vw !important;
      margin-right: 2vw;
    }
    .PJLA  {
      width: 5.189542VW !important;
      height: 4.862745VW !important;
      margin-top: 2.5vw !important;
    }



.F11 {
  display: flex;
  align-items: center;
  width: 64vw;
  margin: auto;
  justify-content: space-between;
  margin-left: 6.2vw;
  margin-top: 1vw;
}
  .F11 h3 {
    width: 4vw;
    text-align: left;
    font-size: 1.1vw;
    color: rgb(121, 121, 121);
    font-weight: 500;
    white-space: nowrap;
  }
  .F11 p {
    font-size: 1.1vw;
    color: rgb(121, 121, 121);
    font-weight: 500;
    margin-top: 0.9vw;
    margin-bottom: 0vw;
    white-space: nowrap;
  }

  .F12 {
    display: flex;
    margin-top: 5vw;
  }
.enviar {
    margin-left: 18vw;
}
.DButton {
  cursor: pointer;
  width: 10.156vw;
  height: 2.292vw;
  border: 0px;
  margin-right: 0.072vw;
  font-size: 0.729vw;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-bottom: 1vw;
  background: #007E94;
  margin-top: 2.7083333333333335VW;
}



.enviar label {
  color: #007E94;
  font-size: 1.6vw;
}
.enviar input {
  width: 22vw;
  font-size: 1vw;
  color: rgb(121, 121, 121);
}




.enviar input::-webkit-file-upload-button {
    text-align: center;
    color: rgb(121, 121, 121);
    background-color: rgb(245, 245, 245);
    font-weight: 700;
    font-size: 0.8vw;
    padding: 0.8vw 0.5vw;
    margin-bottom: 0.03vw;
    border: 0vw;
    cursor: pointer;
}
.enviar input::before {
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.enviar input:hover::before {
  border-color: black;
}

    .F2B2_F4 {
        display: flex;
        width: 34.150326797385624VW;
        height: 2.287581699346405VW;
        align-items: center;
        justify-content: center;
        background-color: var(--color-10);
        border-radius: 0VW 0vw 9.803921568627452vw 0vw;
    }
        .F2B2_F4 p {
            font-size: 1.1437908496732025VW;
            font-weight: 400;
            font-family: var(--Open_Sans);
            color: var(--color-4);
            margin: 0vw;
            white-space: nowrap;
        }


#ipr {
  visibility: hidden;
}

</style>
